<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <div class="h-b">
                    <el-button type="primary" size="small" @click="add">
                        新增
                    </el-button>
                </div>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="getList">
                        刷新
                    </el-button>
                </div>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="save">
                        保存
                    </el-button>
                </div>
            </div>
        </div>
        <div class="form-list">
            <el-form ref="_defineForm">
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        &nbsp;
                    </el-col>
                </el-form-item>

                <el-form-item>
                    <el-col :span="3" class="form-title">
                        默认行业方案：
                    </el-col>
                    <el-col :span="3">
                        <span style="font-weight: 800;">医药行业</span>
                        &nbsp;&nbsp;&nbsp;
                        <el-button type="primary" @click="changeDefine">切换</el-button>
                    </el-col>

                </el-form-item>

                <el-divider></el-divider>

                <el-form-item class="base-align">
                    <el-col :span="1">&nbsp;</el-col>
                    <el-col :span="2">字段名称</el-col>
                    <el-col :span="3">显示名称</el-col>
                    <el-col :span="3">字段类型</el-col>
                    <el-col :span="5">字段选项值</el-col>
                    <el-col :span="1">排序</el-col>
                    <el-col :span="1">显示</el-col>
                    <el-col :span="1">必填</el-col>
                    <el-col :span="1">系统</el-col>
                    <el-col :span="2">操作</el-col>
                </el-form-item>

                <el-form-item v-for="(item, index) in dataSource" :key="item.LottableFiled" class="base-align">

                    <el-col :span="1" class="form-title">
                        {{ (index + 1) }}、
                    </el-col>
                    <el-col :span="2">
                        <el-input v-model="item.FieldName" :disabled="item.IsSystem" :readonly="item.IsSystem"
                            :maxlength="20" :minlength="2" :placeholder="字段名称"></el-input>
                    </el-col>
                    <el-col :span="3">
                        <el-input v-model="item.DisplayName" :disabled="item.IsSystem" :readonly="item.IsSystem"
                            :maxlength="20" :minlength="2" :placeholder="显示名称"></el-input>
                    </el-col>
                    <el-col :span="3">
                        <el-select v-model="item.ControlType" :disabled="item.IsSystem" :readonly="item.IsSystem"
                            @change="onLottableTypeChange" :placeholder="请选择" size="mini">
                            <el-option v-for="item in controlTypes" :key="item.Value" :label="item.Label"
                                :value="item.Value">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="5">
                        <el-input v-model="item.DataSource" :disabled="!(item.ControlType == 4 || item.ControlType == 9)"
                            :readonly="!(item.ControlType == 4 || item.ControlType == 9)" :maxlength="120" :minlength="2"
                            :placeholder="item.ControlType == 9 ? `父类字典编码` : item.ControlType == 4 ? `下拉选项值多个,号隔开` : ''"></el-input>
                    </el-col>
                    <el-col :span="1">
                        <el-input type="number" v-model="item.SortNo" maxlength="5"></el-input>
                    </el-col>
                    <el-col :span="1">
                        <el-checkbox v-model="item.IsVisible"></el-checkbox>
                    </el-col>
                    <el-col :span="1">
                        <el-checkbox v-model="item.IsRequired"></el-checkbox>
                    </el-col>
                    <el-col :span="1">
                        <el-tag v-if="item.IsSystem">是</el-tag>
                        <el-tag type="info" v-else>否</el-tag>

                    </el-col>
                    <el-col :span="2">
                        <el-button v-if="item.ID !== 0" type="text" @click="del(index)">
                            移除</el-button>
                    </el-col>

                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
export default {
    mounted() {
        this.getDefineControlTypeCode();
        this.getList();
    },
    data() {
        return {
            radioDefine: 1,
            configDataSource: {},
            dataSource: [],
            controlTypes: [],
        }
    },
    computed: {

    },
    props: {

    },
    methods: {
        add() {
            this.dataSource.push({
                ID: 0,
                DefineType: 0, //自定义类型（0-货品 1-货主 2-xxx）',
                SortNo: 0,
                ControlType: 1,
                FieldName: "",
                DisplayName: "",
                IsRequired: false,
                IsVisible: false,
                DictParentCode: '',
                DataSource: ''
            });
        },
        del(index) {
            this.dataSource.splice(index, 1);
        },
        changeDefine() {
            this.Utils.messageBox(i18n.t(`更多行业接入中...`), "warning");
        },
        getList() {
            this.$ajax.send("omsapi/definefield/getlist", "get", {}, (data) => {
                this.dataSource = data.Result;
            });
        },
        getDefineControlTypeCode() {
            this.$ajax.send("omsapi/sysdictionary/getenumsource", "get", { enumName: 'com.oms.model.enums.DefineControlTypeCode' }, (result) => {
                this.controlTypes = result.Result;
            });
        },
        save() {
            for (var i = 0; i < this.dataSource.length; i++) {
                var item = this.dataSource[i];
                if (item.FieldName == '' || item.DisplayName == '') {
                    this.Utils.messageBox("第" + (i + 1) + `行字段名称和显示名称不能为空`, "warning");
                    return;
                }
                if (item.DataSource == '' && (item.ControlType == 4 || item.ControlType == 9)) {
                    this.Utils.messageBox("第" + (i + 1) + `行，字段选项值必填`, "warning");
                    return;
                }
            }

            this.$ajax.send("omsapi/definefield/save", "post", this.dataSource, (data) => {
                this.Utils.messageBox(`保存成功.`, "success");
            });
        }
    }
}
</script>

<style>
.form-title1 {
    text-align: center;
}

.base-align {
    text-align: center;
    border: 0 none;
}
</style>